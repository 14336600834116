import Grid from '@mui/material/Grid'
import cn from '@utils/helpers/cn'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'

const MuiGridContainer = ({ blok }) => {
  return (
    <Grid
      container
      columnSpacing={parseInt(blok.columnSpacing) || 2}
      rowSpacing={parseInt(blok.rowSpacing) || 2}
      className={cn(blok.class)}
      {...storyblokEditable(blok)}
    >
      {blok.blocks
        ?.filter(
          (b) =>
            (b.hasOwnProperty('showBlock') && b.showBlock) ||
            !b.hasOwnProperty('showBlock'),
        )
        .map((nestedBlok, index) => (
          <StoryblokComponent
            index={index}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
    </Grid>
  )
}

export default MuiGridContainer
